import request from '@/utils/request'
const BASE_API = process.env.VUE_APP_BASE_API

export function getFormatKeyword(param) {
  return request({
    url: `${BASE_API}/api/sub/get_format_keyword`,
    method: 'get',
    params: param,
  })
}
