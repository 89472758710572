<template>
  <div class="views">
    <div class="search_div"
         v-show="isShow">
      <el-form ref="form"
               :rules="rules"
               :model="form"
               label-position="left"
               label-width="110px"
               style="min-width: 580px">
        <el-row :gutter="40">
          <el-col :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12">
            <div v-if="$store.state.user.loginInfo.cat_list">
              <div class="filter-module">
                <div class="filter-module-title">{{$t('search.module_title_cats')}}</div>
                <el-form-item :label="$t('search.plat') + ':'"
                              :label-width="'80px'">
                  <Platform @showPlats="showPlats"
                            :all-disabled="!!form.comment_url_id"
                            @selectedPlats="changeSelectedPlats"
                            :default-plat="form.plat" />
                </el-form-item>
                <el-form-item :label="$t('search.cat') + ':'"
                              :label-width="'80px'">
                  <CatTree :showCats="showCats"
                           v-model="cats"
                           :tree-dom-id="'filterCatTreeId'"
                           :width="'430'"
                           :height="'300'"
                           :plat="form.plat"
                           :default-cats="form.cats"
                           :all-disabled="!!form.comment_url_id"
                           @on-changeDateTime="changeDateTime"></CatTree>
                </el-form-item>
              </div>
              <div class="filter-module">
                <div class="filter-module-title">{{$t('search.module_title_title')}}</div>
                <el-form-item :label="$t('search.title') + ':'"
                              :label-width="$i18n.locale == 'en'? '120px' : '110px'">
                  <div v-if="!isOversea"
                       style="position:relative;height:40px"
                       ref="titlePopover">
                    <el-input v-model="form.title"
                              size="mini"
                              :placeholder="$t('search.title_placeholder')"
                              style="max-width: 85%; margin-right: 10px"
                              :style="{width: $i18n.locale == 'en'? '356px' : '395px'}"
                              @blur="getFormatKeywordMethod(form.title, 'blur')"
                              @focus="keywordFocus"></el-input>
                    <el-tooltip placement="top-start">
                      <div slot="content">
                        <p>
                          {{$t('search.title_question_1')}}
                          <span style="color: #ffff00">{{$t('search.title_question_2')}}</span>
                          <span v-if="$i18n.locale == 'zh'">。</span>
                        </p>
                        <p>{{$t('search.title_question_3')}}</p>
                        <p>{{$t('search.title_question_4')}}</p>
                        <p>{{$t('search.title_question_5')}}</p>
                      </div>
                      <i class="el-icon-question"
                         style="margin-right:5px"></i>
                    </el-tooltip>
                    <el-popover width="360"
                                v-model="formatVisible"
                                trigger="manual"
                                placement="top">
                      <el-button class="keyword-search"
                                 size="small"
                                 slot="reference"
                                 @click="getFormatKeywordMethod(form.title, 'click')">检查</el-button>
                      <div v-if="format_parse"
                           style="padding:12px">
                        <i class="el-icon-close"
                           style="position:absolute;cursor:pointer;right:6px;top:6px"
                           @click.stop="formatVisible = false"></i>
                        <div style="font-size:13px">{{parse_word_title}}</div>
                        <div style="font-size:14px;color:#3a63f3;padding:5px 0"
                             v-html="format_parse"></div>
                        <div style="font-size:13px">确认逻辑无误再提交搜索，数据更精准哦</div>
                      </div>
                    </el-popover>
                  </div>
                  <el-popover v-if="isOversea"
                              @click.native.stop="stopClick"
                              v-model="popoverShow.title"
                              placement="bottom-start"
                              width="400"
                              trigger="manual">

                    <div class="title-trans-list"
                         v-for="(item, index) in titleTransRes"
                         :key="index">
                      <i :class="{'checked':item.checked}"
                         class="el-icon-circle-check"></i>
                      <div class="title-trans-list-left">
                        <p @click="titleTransResClick(item)"
                           v-show="!item.edit">{{item.value}}</p>
                        <el-input v-show="item.edit"
                                  size="mini"
                                  @click.native.stop="stopClick"
                                  :placeholder="$t('search.edit_placeholder')"
                                  v-model="item.value">
                        </el-input>

                      </div>
                      <div class="title-trans-list-right">
                        <el-button v-show="!item.edit"
                                   type="primary"
                                   size="mini"
                                   icon="el-icon-edit"
                                   circle
                                   @click.stop="item.edit = true"></el-button>
                        <el-button v-show="item.edit"
                                   type="primary"
                                   size="mini"
                                   icon="el-icon-check"
                                   circle
                                   @click.stop="titleTransEdit(item)"></el-button>
                        <el-button v-show="item.edit"
                                   type="primary"
                                   size="mini"
                                   icon="el-icon-close"
                                   circle
                                   @click.stop="item.value =item.valuecp;item.edit = false"></el-button>
                      </div>

                    </div>
                    <el-input slot="reference"
                              size="mini"
                              :placeholder="$t('search.title_placeholder')"
                              style="width: 300px;"
                              v-model="form.title">
                    </el-input>
                  </el-popover>
                  <el-button size="mini"
                             v-if="isOversea"
                             @click="titleChangeOversea"
                             type="primary"
                             style="vertical-align: middle;height:30px;margin-right: 5px;margin-left:-5px;border-radius: 0 3px 3px 0;position:relative;z-index:1;">
                    {{$t('search.translate')}}</el-button>
                  <el-tooltip v-if="isOversea"
                              placement="right-end">
                    <div slot="content">
                      <p>
                        {{$t('search.title_question_1')}}
                        <span style="color: #ffff00">{{$t('search.title_question_2')}}</span>
                        <span v-if="$i18n.locale == 'zh'">。</span>
                      </p>
                      <p>{{$t('search.title_question_3')}}</p>
                      <p>{{$t('search.title_question_4')}}</p>
                      <p>{{$t('search.title_question_5')}}</p>
                    </div>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                  <!-- <div class="helpInsight"
                       style="margin-left: 10px;"
                       @click.stop="showChatBot()"
                       v-if="isShowChatRobot">{{$t('ai.write')}}</div> -->
                  <div style="margin-top: 10px"
                       v-if="showCats != 8 && !isOversea">
                    <ConceptListCpn :cats="cats"
                                    :plat="form.plat"
                                    @chooseConcept="chooseConcept" />
                  </div>
                </el-form-item>
                <el-form-item v-if="isFilingCat"
                              :label-width="$i18n.locale == 'en'? '120px' : '110px'"
                              :label="$t('filing.filing_name') + ':'">
                  <el-input v-model="form.filing_name"
                            size="mini"
                            :placeholder="$t('filing.filing_name_placeholder')"
                            style="width: 360px; margin-right: 10px"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12"
                  style="min-width: 580px">
            <div class="filter-module"
                 style="height: 350px">
              <div class="filter-module-title">{{$t('search.module_title_filter')}}</div>
              <el-form-item :label="$t('search.time') + ':'"
                            class="timePick">
                <MonthPickerCpn v-model="dateTime"
                                @change="dateChange"
                                :max-date="$store.state.user.deadLineEndMonth"
                                :min-date="$store.state.user.deadLineStartMonth" />
              </el-form-item>
              <el-form-item :label="$t('search.brand') + ':'"
                            v-show="showBrand">
                <el-input v-model="form.brand"
                          size="mini"
                          :placeholder="$t('search.brand_placeholder')"
                          style="width: 360px; margin-right: 10px"></el-input>
                <el-tooltip placement="right-end">
                  <div slot="content">
                    <p>
                      {{$t('search.title_question_1')}}
                      <span style="color: #ffff00">{{$t('search.title_question_2')}}</span>
                      <span v-if="$i18n.locale == 'zh'">。</span>
                    </p>
                    <p>{{$t('search.title_question_3')}}</p>
                    <p>{{$t('search.title_question_4')}}</p>
                    <p>{{$t('search.title_question_5')}}</p>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item v-if="!isOversea"
                            :label="$t('search.shop') + ':'"
                            :label-width="$i18n.locale == 'en' ? '130px' : '110px'"
                            prop="shop">
                <el-input v-model="form.shop"
                          size="mini"
                          :placeholder="$t('search.shop_placeholder')"
                          style="width: 416px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '340px' : '360px'}"></el-input>
                <el-tooltip placement="right-end">
                  <div slot="content">
                    <p>
                      {{$t('search.title_question_1')}}
                      <span style="color: #ffff00">{{$t('search.title_question_2')}}</span>
                      <span v-if="$i18n.locale == 'zh'">。</span>
                    </p>
                    <p>{{$t('search.title_question_3')}}</p>
                    <p>{{$t('search.title_question_4')}}</p>
                    <p>{{$t('search.title_question_5')}}</p>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item :label="$t('search.avg') + ':'"
                            :label-width="$i18n.locale == 'en' ? '120px' : '110px'">
                <el-input v-model.trim="form.avg_price[0]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '155px' : '160px'}">
                  <i v-if="!isOversea"
                     slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ $t('search.moneyUnit') }}</i>
                  <i v-if="isOversea"
                     slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ moneyUnit }}</i>
                </el-input>
                <span style="margin: 0 16px 0 6px">~</span>
                <el-input v-model.trim="form.avg_price[1]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '155px' : '160px'}">
                  <i v-if="!isOversea"
                     slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ $t('search.moneyUnit') }}</i>
                  <i v-if="isOversea"
                     slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ moneyUnit }}</i>
                </el-input>
                <el-tooltip placement="right">
                  <div slot="content">
                    <p>{{$t('search.avg_question_1')}}
                      {{ $t('search.moneyUnit') }}，{{$t('search.avg_question_2')}}{{ $t('search.moneyUnit') }}≤{{$t('search.avg_question_3')}}≤200{{ $t('search.moneyUnit') }}。</p>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item :label="$t('search.sales') + ':'"
                            :label-width="$i18n.locale == 'en' ? '180px' : '110px'">
                <el-input v-model.trim="form.sold[0]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '125px' : '160px'}">
                  <i slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{$t('search.sold_unit')}}</i>
                </el-input>
                <span style="margin: 0 16px 0 6px">~</span>
                <el-input v-model.trim="form.sold[1]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '125px' : '160px'}">
                  <i slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{$t('search.sold_unit')}}</i>
                </el-input>
                <el-tooltip placement="right">
                  <div slot="content">
                    <p>{{$t('search.avg_question_1')}} {{$t('search.sold_unit')}}，{{$t('search.avg_question_2')}} {{$t('search.sold_unit')}}≤{{$t('search.avg_question_3')}}≤200
                      {{$t('search.sold_unit')}}。
                    </p>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item :label="$t('search.sold') + ':'"
                            :label-width="$i18n.locale == 'en' ? '180px' : '110px'">
                <el-input v-model.trim="form.sales[0]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '125px' : '160px'}">
                  <i slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ $t('search.countUnit1') }}{{ $t('search.moneyUnit') }}</i>
                </el-input>
                <span style="margin: 0 16px 0 6px">~</span>
                <el-input v-model.trim="form.sales[1]"
                          size="mini"
                          style="width: 188px; margin-right: 10px"
                          :style="{width: $i18n.locale == 'en' ? '125px' : '160px'}">
                  <i slot="suffix"
                     style="font-style: normal; color: #7d8a9b">{{ $t('search.countUnit1') }}{{ $t('search.moneyUnit') }}</i>
                </el-input>
                <el-tooltip placement="right">
                  <div slot="content">
                    <p>{{$t('search.avg_question_1')}}
                      {{ $t('search.countUnit1') }}{{ $t('search.moneyUnit') }}，{{$t('search.avg_question_2')}}
                      {{ $t('search.countUnit1') }}{{ $t('search.moneyUnit') }}≤{{$t('search.sales_question_3')}}≤200{{ $t('search.countUnit1') }}{{ $t('search.moneyUnit') }}。
                    </p>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
            </div>
            <div class="filter-module newDiscovery"
                 style="height: 100px">
              <div class="filter-module-title">{{$t('search.module_title_find')}}</div>
              <el-form-item :label="$t('search.shelf_time') + ':'"
                            class="timePick">
                <MonthPickerCpn v-model="onlineTime"
                                :can-empty="true"
                                :disabled="!isTaoOrJd"
                                :max-date="$store.state.user.deadLineEndMonth"
                                :min-date="$store.state.user.deadLineStartMonth" />
              </el-form-item>
              <el-form-item :label="$t('search.filing_date') + ':'"
                            class="timePick"
                            v-if="isFilingCat">
                <MonthPickerCpn v-model="filing_date"
                                :can-empty="true"
                                :max-date="$store.state.user.deadLineEndMonth"
                                :min-date="$store.state.user.deadLineStartMonth" />
              </el-form-item>
            </div>
            <div class="filter-module"
                 v-show="!isMultiPlat">
              <div class="filter-module-title">{{$t('search.search_item_id')}}</div>
              <el-form-item :label="$t('search.item_id')+ ':'">
                <el-input size="mini"
                          type="textarea"
                          :rows="5"
                          style="width: 416px; margin-right: 10px;margin-top: 10px"
                          :style="{width: $i18n.locale == 'en'? '356px' : '395px'}"
                          v-model="form.item_ids"
                          :placeholder="$t('search.item_id_placeholder')"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="filter-module attribute-filter"
               v-if="notMultiPlat">
            <div class="filter-module-title">{{$t('search.module_title_attr')}}</div>
            <div class="defaultTopAttrBox"
                 v-if="!isOversea && !forbidAttr">
              <div v-loading="loadingDefault || errorItemDefault.text == 'RepeatRequest'"
                   v-message="errorItemDefault"
                   style="margin-top: 10px">
                <table class="table"
                       border="0"
                       cellspacing="0"
                       cellpadding="0">
                  <tr v-for="item,index in defaultAttrvList"
                      :key="item.key">
                    <td style="width:100px;padding-bottom:10px;">
                      {{computeAttrKeyLabel(item)}}
                    </td>
                    <td style="padding-bottom:10px;">
                      <el-checkbox-group v-model="checkList[index].check"
                                         @change="defaultAttrCheckChange(index)"
                                         style="display:inline-block;margin-right:10px;">
                        <el-checkbox :label="computeAttrItemLabel(item2)"
                                     v-for="item2 in item.values"
                                     :key="item2"></el-checkbox>
                      </el-checkbox-group>
                      <el-checkbox v-model="checkList[index].seachChecked">
                        <el-input size="mini"
                                  @input="attrRangeInputChange(index)"
                                  v-model="checkList[index].range_search_able_first"
                                  style="width: 80px;">
                        </el-input>
                        <span v-if="item.range_search_able == 1"
                              style="margin: 0 6px;color: #606266">~</span>
                        <el-input v-if="item.range_search_able == 1"
                                  size="mini"
                                  @input="attrRangeInputChange(index)"
                                  v-model="checkList[index].range_search_able_second"
                                  style="width: 80px;">
                        </el-input>
                        <span v-if="item.range_search_unit"
                              style="margin: 0 6px; color: #606266">{{item.range_search_unit}}</span>
                      </el-checkbox>
                      <el-button type="text"
                                 icon="el-icon-refresh"
                                 style="padding:0;margin-left:6px"
                                 v-if="item.page==1&&item.values.length==10"
                                 @click="loadMoreDefaultAttr(item,index)">{{$t('search.load_more')}}</el-button>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:100px;padding-bottom:10px;vertical-align: text-top;padding-top: 10px;">{{$t('search.advanced_option')}}</td>
                    <td>
                      <div style="display: flex">
                        <el-form-item :label="$t('search.item_attr_rela') + ':'">
                          <el-select v-model="form.attrv"
                                     :disabled="forbidAttr"
                                     :class="forbidAttr ? 'forbid-attr-ele' : ''"
                                     :placeholder="$t('search.select_placeholder')"
                                     size="mini"
                                     style="width: 188px; margin-right: 15px">
                            <el-option value="and"
                                       :label="$t('search.and')"></el-option>
                            <el-option value="or"
                                       :label="$t('search.or')"></el-option>
                          </el-select>
                          <div class="dlb"
                               style="margin-left: 40px">
                            <el-button type="text"
                                       icon="el-icon-refresh"
                                       :loading="getTopAttrLoading"
                                       @click="getTopAttrData"
                                       :disabled="forbidAttr">{{$t('search.load_attr')}}</el-button>
                          </div>
                        </el-form-item>
                      </div>
                      <el-form-item :label="$t('search.item_attr') + ':'">
                        <el-select v-model="form.top"
                                   placeholder="请选择"
                                   size="mini"
                                   style="width: 188px"
                                   :disabled="forbidAttr"
                                   :class="forbidAttr ? 'forbid-attr-ele' : ''">
                          <el-option value="1"
                                     :label="$t('search.top_attr')"></el-option>
                          <el-option value="0"
                                     :label="$t('search.full_attr')"></el-option>
                          <!-- <el-option value="2"
                                     v-if="isFilingCat"
                                     :label="$t('search.filing_attr')"></el-option> -->
                        </el-select>
                      </el-form-item>
                      <div v-loading="loading || errorItem.text == 'RepeatRequest'"
                           v-message="errorItem"
                           style="margin-top: 10px; display: flex">
                        <table class="table"
                               border="0"
                               cellspacing="0"
                               cellpadding="0"
                               style="margin-bottom:0;">
                          <tr v-for="item in attrvList"
                              :key="item.id"
                              style="line-height: 40px;">
                            <td>
                              <el-select v-model="item.name"
                                         :placeholder="$t('search.select_placeholder')"
                                         size="mini"
                                         style="width: 188px; margin-right: 10px">
                                <el-option :value="sonItem.name_source || sonItem.name"
                                           :label="sonItem.name_show || sonItem.name"
                                           v-for="sonItem,index in topAttrData"
                                           :disabled="item.isDisabledArr[index]"
                                           @click.native="getAttrvListIndex()"
                                           :key="sonItem.id">
                                  <el-tooltip class="item"
                                              effect="dark"
                                              :content="sonItem.name_tip"
                                              :disabled="!sonItem.name_tip"
                                              placement="left-start">
                                    <div>{{sonItem.name_show || sonItem.name}}</div>
                                  </el-tooltip>
                                </el-option>
                              </el-select>
                            </td>
                            <td>
                              <el-input style="width: 188px; margin-right: 20px"
                                        size="mini"
                                        v-model="item.value"
                                        :placeholder="$t('search.attr_placeholder')"></el-input>
                            </td>
                            <td>
                              <i class="el-icon-circle-plus cp btColor mr10"
                                 @click="addTableRow()"></i>
                              <i class="el-icon-remove cp btColor"
                                 @click="deleTableRow(item.id)"></i>
                            </td>
                          </tr>
                        </table>
                        <template>
                          <FilingTable :data="filingAttrData"
                                       :defaultAttr="defaultFilingAttrv"
                                       ref="filingTable" />
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div v-else
                 style="margin-top: 10px">
              <table>
                <tr>
                  <td style="width:100px;padding-bottom:10px;vertical-align: text-top;padding-top: 10px;">{{$t('search.advanced_option')}}</td>
                  <td>
                    <el-form-item :label="$t('search.item_attr') + ':'">
                      <el-select v-model="form.top"
                                 :placeholder="$t('search.select_placeholder')"
                                 size="mini"
                                 style="width: 188px"
                                 :disabled="forbidAttr"
                                 :class="forbidAttr ? 'forbid-attr-ele' : ''">
                        <el-option value="1"
                                   :label="$t('search.top_attr')"></el-option>
                        <el-option value="0"
                                   :label="$t('search.full_attr')"></el-option>
                      </el-select>
                      <div class="dlb"
                           style="margin-left: 40px">
                        <el-button type="text"
                                   icon="el-icon-refresh"
                                   :loading="getTopAttrLoading"
                                   @click="getTopAttrData"
                                   :disabled="forbidAttr">{{$t('search.load_attr')}}</el-button>
                      </div>
                    </el-form-item>
                    <div style="display: flex">
                      <el-form-item :label="$t('search.item_attr_rela') + ':'">
                        <el-select v-model="form.attrv"
                                   :placeholder="$t('search.select_placeholder')"
                                   size="mini"
                                   :disabled="forbidAttr"
                                   :class="forbidAttr ? 'forbid-attr-ele' : ''"
                                   style="width: 188px; margin-right: 15px">
                          <el-option value="and"
                                     :label="$t('search.and')"></el-option>
                          <el-option value="or"
                                     :label="$t('search.or')"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-loading="loading || errorItem.text == 'RepeatRequest'"
                         v-message="errorItem"
                         style="margin-top: 10px">
                      <table class="table"
                             border="0"
                             cellspacing="0"
                             cellpadding="0"
                             style="margin-bottom:0;">
                        <tr v-for="item in attrvList"
                            :key="item.id"
                            style="line-height: 40px;">
                          <td>
                            <el-select v-model="item.name"
                                       :placeholder="$t('search.select_placeholder')"
                                       size="mini"
                                       style="width: 188px; margin-right: 10px">
                              <el-option :value="sonItem.name_source || sonItem.name"
                                         :label="sonItem.name_show || sonItem.name"
                                         v-for="sonItem,index in topAttrData"
                                         :disabled="item.isDisabledArr[index]"
                                         @click.native="getAttrvListIndex()"
                                         :key="sonItem.id">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="sonItem.name_tip"
                                            :disabled="!sonItem.name_tip"
                                            placement="left-start">
                                  <div>{{sonItem.name_show || sonItem.name}}</div>
                                </el-tooltip>
                              </el-option>
                            </el-select>
                          </td>
                          <td>
                            <el-input style="width: 188px; margin-right: 20px"
                                      size="mini"
                                      v-model="item.value"
                                      :placeholder="$t('search.attr_placeholder')"></el-input>
                          </td>
                          <td>
                            <i class="el-icon-circle-plus cp btColor mr10"
                               @click="addTableRow()"></i>
                            <i class="el-icon-remove cp btColor"
                               @click="deleTableRow(item.id)"></i>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>

            </div>
          </div>

        </el-row>
        <el-row :gutter="40">
          <el-col :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12"
                  style="width: 50%;height:1px;">
          </el-col>
          <el-col :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="12"
                  :xl="12">
            <el-form-item label-width="0">
              <div>
                <el-button type="primary"
                           size="small"
                           @click="submitForm('ruleForm')"
                           :disabled="searching"
                           style="width: 120px; height: 40px;margin-top: 20px"
                           class="form-search">{{  searching ? $t('search.searching') : $t('search.search')  }}</el-button>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <Popper v-if="forbidAttr"
            ref="popper"
            :popId="'ForbidAttrPop'"
            placement="bottom"
            target=".forbid-attr-ele"
            :content="$t('search.attr_forbid_tooltip')"></Popper>
  </div>
</template>

<script>
import {
  topAttr,
  topDefaultAttr,
  getMoreDefaultAttr,
  titleTrans,
  filingTopAttr,
} from '@/api/search'
import { getUid } from '@/api/public'
import {
  parseTime,
  handelBrandNameOptions,
  getObjFromUrl,
  build_ts,
  getMoneySymbol,
  removeDoubleQuotes,
  wrapInDoubleQuotes,
  isMultiPlat,
  platSortFenxi,
} from '@/utils'

import Platform from '@/components/searchCpn/Platform.vue'
import CatTree from '@/components/searchCpn/CatTree.vue'
import MonthPickerCpn from '../components/monthPickerCpn/MonthPickerCpn.vue'
import ConceptListCpn from '@/components/ConceptList/ConceptList.vue'
//import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { timeRnage } from '@/mixins/mixins.js'
import { DateTime } from 'luxon'
import { LuxonTime } from 'common'
import Popper from '@/components/Popper/Popper.vue'
import FilingTable from '../components/FilingTable.vue'
import { getFormatKeyword } from '@/api/format.js'
export default {
  name: 'filters',
  mixins: [timeRnage],
  components: {
    Platform,
    CatTree,
    MonthPickerCpn,
    ConceptListCpn,
    Popper,
    FilingTable,
  },
  data() {
    return {
      isOversea: this.$store.state.user.isOversea,
      popoverShow: {
        title: false,
      },
      titleTransRes: [],
      dateTime: [
        this.$store.state.user.startMonth,
        this.$store.state.user.endMonth,
      ],
      platTimeKey: '',
      platTimeName: this.$store.state.user.isOversea ? 'amazon_us' : 'taobao',
      ts: this.$store.state.user.ts,
      timeList: [],
      isShow: true,
      getTopAttrLoading: false,
      label: '',
      form: {
        plat: '',
        cats: [],
        cid: {},
        brand: '',
        brand_id: '',
        shop: '',
        shop_id: '',
        title: '',
        avg_price: ['', ''],
        sold: ['', ''],
        sales: ['', ''],
        attrv: 'and',
        top: '1',
        startMonth: this.$store.state.user.endMonth,
        endMonth: this.$store.state.user.endMonth,
        attrv_and: [],
        attrv_or: [],
        topAttrList: [],
        title_relation: 'and',
        comment_url_id: '',
        filing_name: '',
        item_ids: '',
      },
      onlineTime: ['', ''],
      rules: {
        plat: [
          {
            required: true,
            message: this.$t('search.please_select_plat'),
            trigger: 'change',
          },
        ],
        startMonth: [
          {
            type: 'date',
            required: true,
            message: this.$t('search.please_select_date'),
            trigger: 'change',
          },
        ],
        endMonth: [
          {
            type: 'date',
            required: true,
            message: this.$t('search.please_select_time'),
            trigger: 'change',
          },
        ],
      },
      param_val: getObjFromUrl(window.location.href),
      platList: [
        {
          id: '1',
          key: 'cshop',
          value: '淘宝渠道',
          fix_plat: 'taobao',
          enabled: true,
        },
        {
          id: '2',
          key: 'tmall',
          value: '天猫渠道',
          fix_plat: 'tmall',
          enabled: false,
        },
        {
          id: '3',
          key: 'jd',
          value: '京东渠道',
          fix_plat: 'jd',
          enabled: false,
        },
      ],
      cat1: '',
      cat2: '',
      cat3: '',
      startMonth: this.$store.state.user.startMonth,
      endMonth: this.$store.state.user.endMonth,
      defaultAttrvList: [],
      checkList: [],
      defaultCheckList: [],
      loadingDefault: false,
      errorItemDefault: { type: 'info', text: '' },
      attrvList: [],
      attrKeys: [], // 属性keys,params中为必需参数
      topAttrData: [],
      topAttrCustomobj: {},
      topAttrList: [],
      attr_show: true, //判断是否添加属性筛选条件
      errorItem: { type: 'info', text: '' },
      loading: false,

      showCats: 0,
      cats: [],
      cid: {},
      sub_name: '',
      nub: 0,
      newDisabledArr: [],
      shelfTime: ['', ''],
      clientHeight: document.body.clientHeight,
      // 属性禁用
      forbidAttr: false,
      filing_date: ['', ''],
      isFilingCat: false,
      filingAttrData: [],
      defaultFilingAttrv: [],
      showBrand: true,
      searching: false,
      format_parse: '',
      parse_word_title: '',
      formatVisible: false,
      isBlur: '',
    }
  },
  computed: {
    isFenxiEn() {
      return !this.isOversea && localStorage.getItem('language') == 'en'
    },
    notMultiPlat() {
      if (this.isOversea) {
        return true
      } else {
        return !(isMultiPlat(this.form.plat) && this.sub_name != 'custom_102')
      }
    },
    isMultiPlat() {
      return isMultiPlat(this.form.plat)
    },
    moneyUnit() {
      return this.$store.state.user.moneySymbol
    },
    languageMode() {
      return this.$store.state.user.languageMode
    },
    fenxiLanguageMode() {
      return this.$store.state.user.fenxiLanguageMode
    },
    formParam() {
      let plat = _.map(
        _.filter(this.platList, function (o) {
          return o.enabled
        }),
        'key'
      ).join(',')
      let filterParams = {
        attrv_and: this.form.attrv_and,
        attrv_or: this.form.attrv_or,
        avg_price: this.form.avg_price,
        brand: this.form.brand,
        brand_id: this.form.brand_id,
        cats: this.cats,
        cid: this.cid,
        keys: this.attrKeys,
        plat: this.form.plat,
        sales: this.form.sales,
        shop: this.form.shop,
        shop_id: this.form.shop_id,
        sold: this.form.sold,
        sub_name: this.sub_name,
        time: [this.dateTime],
        title: this.form.title.trim(),
        top: this.form.top,
        topAttrList: this.topAttrList,
        title_relation: this.form.title_relation,
        online_time: this.isTaoOrJd ? this.onlineTime : ['', ''],
        comment_url_id: this.form.comment_url_id,
        filing_date: this.filing_date,
        filing_name: this.form.filing_name,
        item_ids: this.form.item_ids,
      }
      if (this.form.title && this.form.title_trans) {
        filterParams.title_trans = this.form.title_trans
      }
      return filterParams
    },
    isTaoOrJd() {
      const taoOrJd = [1, 2, 3, 4, 11]
      if (taoOrJd.indexOf(this.showCats) > -1) {
        return true
      } else if (this.isOversea) {
        if (
          this.form.plat.includes('shopee') ||
          this.form.plat.includes('amazon')
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    fenxiLanguageMode() {
      return this.$store.state.user.fenxiLanguageMode
    },
    // isShowChatRobot() {
    //   return this.$store.state.user.isShowChatRobot
    // },
  },
  methods: {
    computeAttrKeyLabel(item) {
      if (!this.isOversea && localStorage.getItem('language') == 'en') {
        if (this.fenxiLanguageMode == 'zh') {
          return item.key_source ? item.key_source : item.key
        } else if (this.fenxiLanguageMode == 'en') {
          return item.key
        } else {
          return item.key_source ? item.key_source + '/' + item.key : item.key
        }
      } else {
        return item.key
      }
    },
    computeAttrItemLabel(item) {
      if (!this.isOversea && localStorage.getItem('language') == 'en') {
        if (this.fenxiLanguageMode == 'zh') {
          return item[1] ? item[1] : item[0]
        } else if (this.fenxiLanguageMode == 'en') {
          return item[0]
        } else {
          return item[1] ? item[1] + '/' + item[0] : item[0]
        }
      } else if (Array.isArray(item)) {
        return item[0]
      } else {
        return item
      }
    },
    // showChatBot() {
    //   if (this.$store.state.user.createdChatBot) {
    //     this.$store.dispatch('user/changeShowChatBot', true)
    //   } else {
    //     this.$store.dispatch('user/changeCreatedChatBot', true)
    //     this.$store.dispatch('user/changeShowChatBot', true)
    //   }
    //   let botParam = Object.assign({}, this.$store.state.user.chatParams)
    //   botParam.type = 'write'
    //   botParam.tab = 'filters'
    //   botParam.question =
    //     '您可以用自然语言描绘过滤条件，我可以帮您输出逻辑语言，如输入“包含安踏或者anta，但是不包含‘儿童’或者kids”，会输出"(安踏 or anta) and not (儿童 or kids)"'
    //   this.$store.dispatch('user/changeChatParams', botParam)
    // },
    attrRangeInputChange(index) {
      this.$forceUpdate()
      if (
        this.judgeRangeSearch(
          this.checkList[index].range_search_able_first,
          this.checkList[index].range_search_able_second
        )
      ) {
        this.checkList[index].seachChecked = true
      }
    },
    changeDateTime(dateTime) {
      let month = [
        this.$store.state.user.deadLineStartMonth,
        this.$store.state.user.deadLineEndMonth,
      ]
      if (dateTime.length > 0) {
        let deadLine = []
        if (dateTime[0] >= month[0]) {
          if (dateTime[0] > month[1]) {
            deadLine = month
            return
          }
          deadLine[0] = dateTime[0]
        } else {
          deadLine[0] = month[0]
        }
        if (dateTime[1] >= month[1]) {
          deadLine[1] = month[1]
        } else {
          deadLine[1] = dateTime[1]
        }
        this.$store.dispatch('user/changeDeadLineStartMonth', deadLine[0])
        this.$store.dispatch('user/changeDeadLineEndMonth', deadLine[1])
        // 当选择时间超过权限时间,默认修改为权限范围时间内
        if (
          DateTime.fromISO(this.dateTime[0]).ts <
          DateTime.fromISO(deadLine[0]).ts
        ) {
          this.$store.dispatch('user/changeStartMonth', deadLine[0])
          this.dateTime[0] = deadLine[0]
        }
        if (
          DateTime.fromISO(this.dateTime[0]).ts >
          DateTime.fromISO(deadLine[1]).ts
        ) {
          this.$store.dispatch('user/changeStartMonth', deadLine[1])
          this.dateTime[0] = deadLine[1]
        }
        if (
          DateTime.fromISO(this.dateTime[1]).ts >
          DateTime.fromISO(deadLine[1]).ts
        ) {
          this.$store.dispatch('user/changeEndMonth', deadLine[1])
          this.dateTime[1] = deadLine[1]
        }
        if (
          DateTime.fromISO(this.dateTime[1]).ts <
          DateTime.fromISO(deadLine[0]).ts
        ) {
          this.$store.dispatch('user/changeEndMonth', deadLine[1])
          this.dateTime[1] = deadLine[1]
        }
        this.dateChange(this.dateTime)
        localStorage.setItem('deadLineStartMonth', deadLine[0])
        localStorage.setItem('deadLineEndMonth', deadLine[1])
      }
    },
    dateChange(val) {
      if (val) {
        this.form.startMonth = val[0]
        this.form.endMonth = val[1]
      }
    },
    getTimeList() {
      if (this.sub_name) {
        this.platTimeKey = [this.platTimeName, this.sub_name].join('|')
      } else {
        this.platTimeKey = this.platTimeName
      }
      if (this.ts) {
        this.timeList = build_ts(
          this.ts[this.platTimeKey].end,
          this.ts[this.platTimeKey].start
        )
        this.$store.dispatch(
          'user/changeDeadLineStartMonth',
          this.ts[this.platTimeKey].start
        )
        this.$store
          .dispatch(
            'user/changeDeadLineEndMonth',
            this.ts[this.platTimeKey].end
          )
          .then((res) => {
            // 平台的权限时间与时间选择器同步修改
            const endTs = this.ts[this.platTimeKey].end
            const startTs = this.ts[this.platTimeKey].start
            //
            if (
              DateTime.fromISO(this.dateTime[0]).ts <
                DateTime.fromISO(startTs).ts ||
              DateTime.fromISO(this.dateTime[1]).ts > DateTime.fromISO(endTs).ts
            ) {
              this.dateTime = [endTs, endTs]
              this.dateChange(this.dateTime)
            }
          })
      }
    },
    showPlats(val) {
      if (typeof val == 'string' && val.indexOf('tiktok') > -1) {
        this.showBrand = false
        this.form.brand = ''
        this.form.brand_id = ''
      } else {
        this.showBrand = true
      }
      this.showCats = val
    },
    changeSelectedPlats(val) {
      if (val.length > 0) {
        let arr = val.map((item) => {
          if (
            item == 'cshop' ||
            item == 'global' ||
            item == 'tmall' ||
            item == 'tmallhk' ||
            item == 'chaoshi' ||
            item == 'tmallhk_gfzy' ||
            item == 'nochaoshi' ||
            item == 'notmallhk' ||
            item == 'tmqjd' ||
            item == 'tmzyzmd'
          ) {
            return 'taobao'
          } else if (
            item == 'jd' ||
            item == 'jd_only_self' ||
            item == 'jd_exclude_self' ||
            item == 'jd_hk' ||
            item == 'jd_home_self' ||
            item == 'jd_home_pop' ||
            item == 'jd_not_home_self' ||
            item == 'jd_not_home_pop'
          ) {
            return 'jd'
          } else if (
            item == 'amazon_us' ||
            item == 'amazon_de' ||
            item == 'amazon_es' ||
            item == 'amazon_fr' ||
            item == 'amazon_it' ||
            item == 'amazon_uk' ||
            item == 'amazon_jp' ||
            item == 'amazon_ae' ||
            item == 'amazon_sa' ||
            item == 'amazon_br' ||
            item == 'amazon_mx'
          ) {
            return item
          } else {
            return item
          }
        })
        this.platTimeName = _.uniq(arr).join()
      }
      this.formatVisible = false
      this.format_parse = ''
      this.form.plat = val.join()
      this.$store.dispatch('user/changeplants', this.form.plat)
      // this.$store.dispatch(
      //   'user/changeMoneySymbol',
      //   getMoneySymbol(this.form.plat)
      // )
    },
    keywordFocus() {
      this.formatVisible = false
      this.format_parse = ''
    },
    getFormatKeywordMethod(word, type) {
      if (this.formatVisible || this.isBlur == 'blur') {
        return
      }
      if (type == 'blur') {
        this.isBlur = 'blur'
      }
      if (word && word !== '') {
        const param = {
          word: word
        }
        getFormatKeyword(param)
          .then((res) => {
            if (res.status == 'ok') {
              if (res.result.state == 'ok') {
                this.parse_word_title = '系统会按照以下格式来理解您输入的关键词表达式'
                this.format_parse = res.result.format_parse
              } else if (res.result.state == 'error') {
                this.parse_word_title = '您的关键词表达式有误！'
                let textArr = word.split('')
                textArr[[res.result.format_parse]] = `<span style="color:red">${
                  textArr[res.result.format_parse]
                }</span>`
                this.format_parse = textArr.join('')
              }
              this.formatVisible = true
            }
          })
      } else if (!word && type == 'click') {
        this.$message.warning('请输入关键词！')
      }
    },
    handleClickOutsideTitle(event) {
      if (this.isBlur) {
        this.isBlur = ''
        return
      }
      if (
        this.$refs.titlePopover &&
        !this.$refs.titlePopover.contains(event.target) &&
        this.formatVisible
      ) {
        this.formatVisible = false
      }
    },
    loadMoreDefaultAttr(item, index) {
      this.loadingDefault = true
      let param = {
        cid: this.formParam.cid,
        plat: this.formParam.plat,
        start: parseTime(this.form.startMonth, '{y}-{m}'),
        end: parseTime(this.form.endMonth, '{y}-{m}'),
        attr_key: item.key_source ? item.key_source : item.key,
        page: '2',
        page_size: '10',
        site_lang: localStorage.getItem('language'),
      }
      getMoreDefaultAttr(param)
        .then((res) => {
          if (res.status == 'ok') {
            let arr = _.concat(item.values, res.result.values)
            this.defaultAttrvList[index].values = arr
            this.defaultAttrvList[index].page = 2
          }
          this.loadingDefault = false
          this.$set(this.errorItemDefault, 'type', 'info')
          this.$set(this.errorItemDefault, 'text', '')
        })
        .catch((e) => {
          console.error(e)
          this.loadingDefault = false
          this.$set(this.errorItemDefault, 'type', 'error')
          this.$set(this.errorItemDefault, 'text', e.backMsg)
        })
        .finally(() => {
          this.loadingDefault = false
        })
    },
    getTopDefaultAttrData() {
      if (this.form.plat.length == 0) {
        this.$message.warning(this.$t('search.please_select_plat'))
        return false
      }
      if (this.formParam.cats.length == 0) {
        this.$message.warning(this.$t('search.cat_null_tooltip'))
        return false
      }
      this.loadingDefault = true
      this.$set(this.errorItemDefault, 'type', 'info')
      this.$set(this.errorItemDefault, 'text', '')
      let param = {
        cid: this.formParam.cid,
        plat: this.formParam.plat,
        start: parseTime(this.form.startMonth, '{y}-{m}'),
        end: parseTime(this.form.endMonth, '{y}-{m}'),
        site_lang: localStorage.getItem('language'),
      }
      topDefaultAttr(param)
        .then((res) => {
          if (res.status == 'ok') {
            this.defaultAttrvList = _.map(res.result, function (item) {
              return {
                ...item,
                page: 1,
              }
            })
            this.checkList = _.map(res.result, function (item) {
              return {
                ...item,
                check: [],
                seachChecked: false,
              }
            })
            this.refreshCheckListCheck()
          }
          this.loadingDefault = false
          this.$set(this.errorItemDefault, 'type', 'info')
          this.$set(this.errorItemDefault, 'text', '')
        })
        .catch((e) => {
          console.error(e)
          this.loadingDefault = false
          this.$set(this.errorItemDefault, 'type', 'error')
          this.$set(this.errorItemDefault, 'text', e.backMsg)
        })
        .finally(() => {
          this.loadingDefault = false
        })
    },
    defaultAttrCheckChange(val) {
      // console.log(this.defaultCheckList[val].check,this.checkList[val].check)
      //   console.log(_.find(this.checkList, function(o) { return o.ids.length != o.check.length; }))
      //   console.log(_.findIndex(this.checkList, function(o) { return o.ids.length != o.check.length; }))
      // console.log(this.attrvList)
      // console.log(_.differenceWith(this.checkList, this.defaultCheckList, _.isEqual));
    },
    getTopAttrData() {
      if (this.formParam.plat.length == 0) {
        this.$message.warning(this.$t('search.please_select_plat'))
        return false
      }
      if (this.formParam.cats.length == 0) {
        this.$message.warning(this.$t('search.cat_null_tooltip'))
        return false
      }
      this.loading = true
      this.getTopAttrLoading = true
      this.$set(this.errorItem, 'type', 'info')
      this.$set(this.errorItem, 'text', '')
      let param = {
        brand: this.formParam.brand,
        brand_id: this.formParam.brand_id,
        cid: this.formParam.cid,
        plat: this.formParam.plat,
        shop: this.formParam.shop,
        shop_id: this.formParam.shop_id,
        sub_name: this.formParam.sub_name,
        time: this.formParam.time,
        title: this.formParam.title,
        top: this.formParam.top,
        title_relation: this.formParam.title_relation,
        site_lang: this.$i18n.locale,
      }

      let api = topAttr
      return api(param)
        .then((res) => {
          if (res.status == 'ok') {
            this.topAttrList = _.map(res.result, function (item) {
              return [item[0], item[1], item[1], item[2]]
            })
            sessionStorage.setItem(
              'topAttrList',
              JSON.stringify(this.topAttrList)
            )
            this.topAttrData = _.map(res.result, function (item) {
              return {
                id: _.uniqueId(),
                type: item[0],
                name: item[1],
                name_source: item[2] || item[1],
              }
            })
            this.topAttrData = this.topAttrData.filter((item) => {
              if (item.name.match(/\bis_.*\b/) || item.type == 'filing') {
                return false
              } else {
                return true
              }
            })
            this.filingAttrData = _.map(res.result, function (item) {
              return {
                id: _.uniqueId(),
                type: item[0],
                name: item[1],
                name_source: item[2] || item[1],
              }
            })
              .filter((item) => {
                return item.type == 'filing'
              })
              .map((item) => {
                if (this.isFenxiEn) {
                  let name_show = item.name
                  if (this.fenxiLanguageMode == 'zh') {
                    name_show = item.name_source ? item.name_source : item.name
                  } else if (this.fenxiLanguageMode == 'en') {
                    name_show = item.name
                  } else {
                    name_show = `${item.name_source}/${item.name}`
                  }
                  item['name_show'] = name_show
                }
                return item
              })
            if (this.isOversea) {
              this.topAttrData.forEach((item) => {
                let name_show = item.name,
                  name_tip = ''
                if (this.languageMode == 'system') {
                  name_show = item.name
                  name_tip = item.name_source || ''
                } else if (this.languageMode == 'source') {
                  name_show = item.name_source || ''
                  name_tip = item.name || ''
                } else {
                  name_show = `${item.name}/${item.name_source}`
                }
                item['name_show'] = name_show
                item['name_tip'] = name_tip
              })
            } else if (this.isFenxiEn) {
              this.topAttrData.forEach((item) => {
                let name_show = item.name,
                  name_tip = ''
                if (this.fenxiLanguageMode == 'zh') {
                  name_show = item.name_source ? item.name_source : item.name
                } else if (this.fenxiLanguageMode == 'en') {
                  name_show = item.name
                } else {
                  name_show = item.name_source
                    ? `${item.name_source}/${item.name}`
                    : item.name
                }
                item['name_show'] = name_show
              })
            }

            var topAttrCustomobj = {}
            _.each(res.result, (attr) => {
              topAttrCustomobj[attr[1]] = attr[0]
              if (attr[2] && this.isOversea) {
                topAttrCustomobj[attr[2]] = attr[0]
              } else if (attr[2]) {
                topAttrCustomobj[attr[2]] = attr[0]
              }
            })
            this.topAttrCustomobj = topAttrCustomobj
            this.attrvList = [
              {
                id: _.uniqueId(),
                value: '',
                type: '',
                name: '',
                isDisabledArr: this.newDisabledArr,
              },
            ]
            if (this.filingAttrData.length > 0) {
              this.$refs.filingTable.init()
            } else {
              this.$refs.filingTable.hide()
            }
          }
          this.loading = false
          this.getTopAttrLoading = false
          this.$set(this.errorItem, 'type', 'info')
          this.$set(this.errorItem, 'text', '')
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
          this.getTopAttrLoading = false
          this.$set(this.errorItem, 'type', 'error')
          this.$set(this.errorItem, 'text', e.backMsg)
        })
        .finally(() => {
          this.loading = false
          this.getTopAttrLoading = false
        })
    },
    getAttrvListIndex() {
      let valueArr = _.compact(_.map(this.attrvList, 'name'))
      let attrArr = _.map(this.topAttrData, 'name')
      let arr = []
      for (let i = 0; i < attrArr.length; i++) {
        arr.push(false)
        for (let j = 0; j < valueArr.length; j++) {
          if (attrArr[i] == valueArr[j]) {
            arr[i] = true
          }
        }
      }
      for (let i = 0; i < this.attrvList.length; i++) {
        this.attrvList[i].isDisabledArr = arr
      }
      this.$forceUpdate()
    },
    addTableRow() {
      this.getAttrvListIndex()
      this.attrvList.push({
        id: _.uniqueId(),
        name: '',
        type: '',
        value: '',
        isDisabledArr: this.attrvList[0].isDisabledArr,
      })
    },
    deleTableRow(id) {
      if (this.attrvList.length <= 1) {
        this.attrvList = [
          {
            id: _.uniqueId(),
            name: '',
            type: '',
            value: '',
            isDisabledArr: this.newDisabledArr,
          },
        ]
        this.$message({
          message: this.$t('search.attr_least_one'),
          type: 'warning',
        })
        return
      }
      let index = this.attrvList.findIndex((item) => item.id === id)
      this.attrvList.splice(index, 1)
      this.getAttrvListIndex()
    },
    refreshCheckListCheck() {
      if (this.defaultCheckList.length > 0) {
        for (let i = 0; i < this.checkList.length; i++) {
          let rawData = [],
            arr = []
          for (let j = 0; j < this.defaultCheckList.length; j++) {
            if (this.checkList[i].key == this.defaultCheckList[j].name) {
              rawData = this.defaultCheckList[j].value
                .split(' or ')
                .map((item) => removeDoubleQuotes(item))
              arr = rawData.filter((v) =>
                this.defaultAttrvList[i].values.some((item) => item === v)
              )

              this.checkList[i].check = arr
              let vlaues = rawData.filter(
                (v) =>
                  !this.defaultAttrvList[i].values.some((item) => item === v)
              )
              if (vlaues.length > 0) {
                this.checkList[i].seachChecked = true
                this.checkList[i].range_search_able_first = vlaues.join(' or ')
              }
              // range 是否存在区间搜索
              if (this.defaultCheckList[j].range) {
                this.checkList[i].seachChecked = true
                const range_value = this.defaultCheckList[j].range.value_range
                this.checkList[i].range_search_able_first = range_value[0]
                this.checkList[i].range_search_able_second = range_value[1]
              }
            }
          }
        }
      }
    },
    judgeRangeSearch(first, second) {
      if (first && second) {
        if (Number(first) && Number(second)) {
          if (Number(first) > Number(second)) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    submitForm() {
      let self = this
      let arr = this.attrvList.map(function (a) {
        let obj = {}
        if (a.name) {
          obj[a.name] = a.value
          obj['attr_type'] = self.topAttrCustomobj[a.name]
          self.attrKeys.push({
            attr_type: self.topAttrCustomobj[a.name],
            attr: a.name,
          })
          //   obj.popular_attr_prompt = 'input'
        } else {
          obj = null
        }
        return obj
      })
      let filingArr = this.$refs.filingTable
        ? this.$refs.filingTable.attrvList.map(function (a) {
            let obj = {}
            if (a.name) {
              obj[a.name] = a.value
              obj['attr_type'] = self.topAttrList.find((item) => {
                return item[0] == 'filing' && item[1] == a.name
              })[0]
              self.attrKeys.push({
                attr_type: obj['attr_type'],
                attr: a.name,
              })
              //   obj.popular_attr_prompt = 'input'
            } else {
              obj = null
            }
            return obj
          })
        : []
      if (filingArr.length > 0) {
        arr = arr.concat(filingArr)
      }
      let defaultArr = []
      for (let i = 0; i < this.checkList.length; i++) {
        let obj = {}
        const checkItem = this.checkList[i]
        if (
          this.checkList[i].check.length != 0 ||
          (this.checkList[i].seachChecked &&
            this.checkList[i].range_search_able_first)
        ) {
          if (this.checkList[i].seachChecked) {
            if (this.checkList[i].range_search_able == 1) {
              if (
                this.judgeRangeSearch(
                  this.checkList[i].range_search_able_first,
                  this.checkList[i].range_search_able_second
                )
              ) {
                obj.key = this.checkList[i].key
                obj.value = this.checkList[i].check
                  .map((item) => wrapInDoubleQuotes(item))
                  .join(' or ')
                obj['attr_type'] = 'not_custom'
                obj.popular_attr_prompt = 'checkbox'
                obj.range = {
                  value_range: [
                    Number(checkItem.range_search_able_first),
                    Number(checkItem.range_search_able_second),
                  ],
                  unit: checkItem.range_search_unit,
                }
                defaultArr.push(obj)
              } else {
                this.$message.warning(this.$t('search.attr_set_error'))
                return
              }
            } else {
              let arr = this.checkList[i].check.concat(
                this.checkList[i].range_search_able_first
              )
              obj.key = this.checkList[i].key
              obj.value = arr
                .map((item) => wrapInDoubleQuotes(item))
                .join(' or ')
              obj['attr_type'] = 'not_custom'
              obj.popular_attr_prompt = 'checkbox'
              defaultArr.push(obj)
            }
          } else {
            obj.key = this.checkList[i].key
            obj.value = this.checkList[i].check
              .map((item) => wrapInDoubleQuotes(item))
              .join(' or ')
            obj['attr_type'] = 'not_custom'
            obj.popular_attr_prompt = 'checkbox'
            defaultArr.push(obj)
          }
        }
      }
      //把默认展示的top5属性选择的分类和原有填写的属性分类去重合并
      let mergeArr = _.unionWith(arr, defaultArr, _.isEqual)
      if (this.form.attrv == 'and') {
        this.form.attrv_and = _.compact(mergeArr)
        this.form.attrv_or = []
      } else if (this.form.attrv == 'or') {
        this.form.attrv_and = []
        this.form.attrv_or = _.compact(mergeArr)
      }
      if (mergeArr.length == 0) {
        this.form.attrv_and = []
        this.form.attrv_or = []
        this.attrvList = []
      }

      if (this.formParam.plat.length == 0) {
        this.$message.warning(this.$t('search.please_select_plat'))
        return false
      }
      if (!this.isOversea && this.formParam.cats.length == 0) {
        this.$message.warning(this.$t('search.cat_null_tooltip'))
        return false
      }
      if (
        this.topAttrList.length > 0 &&
        this.topAttrList[0][2] !== '请选择属性'
      ) {
        this.topAttrList.unshift([
          '',
          '-',
          this.$t('search.please_select_attr'),
        ])
        this.formParam.topAttrList = this.topAttrList
      }
      if (this.isOversea) {
        let titleTrans = this.titleTransRes.find((item) => {
          return item.checked
        })
        if (titleTrans) {
          this.formParam.title_trans = titleTrans.value
        }
        if (
          this.formParam.cats.length == 0 &&
          !this.form.brand &&
          !this.form.title
        ) {
          this.$message.warning(this.$t('search.cat_least_one'))
          return false
        }
      }
      this.formParam.attrv_and = this.form.attrv_and
      this.formParam.attrv_or = this.form.attrv_or
      this.formParam.attr_vague = this.attr_vague
      if (!this.isOversea) {
        this.formParam.plat = platSortFenxi(this.formParam.plat)
      }
      if (this.attr_vague === false) {
        this.formParam.attrv_and.forEach((item) => {
          if (item.value) {
            item.value = removeDoubleQuotes(item.value)
          }
        })
      }
      this.searching = true
      getUid(this.formParam)
        .then((res) => {
          this.searching = false
          if (res.status == 'ok') {
            if (this.isOversea && this.formParam.cats.length == 0) {
              self.$store.dispatch('user/changeActivedTab', 'Catlist')
              localStorage.setItem('passActivedTab', 'Catlist')
            }
            if (!this.isOversea) {
              let platArr = this.formParam.plat.split(',')
              let arr = _.map(platArr, function (item) {
                if (
                  item === 'tmallhk' ||
                  item === 'chaoshi' ||
                  item === 'tmallhk_gfzy' ||
                  item === 'nochaoshi' ||
                  item === 'notmallhk' ||
                  item === 'tmqjd' ||
                  item === 'tmzyzmd'
                ) {
                  return 'tmall'
                } else if (item === 'global') {
                  return 'cshop'
                } else if (
                  item === 'jd_only_self' ||
                  item === 'jd_exclude_self' ||
                  item === 'jd_hk' ||
                  item === 'jd_home_self' ||
                  item === 'jd_home_pop' ||
                  item === 'jd_not_home_self' ||
                  item === 'jd_not_home_pop'
                ) {
                  return 'jd'
                } else {
                  return item
                }
              })
              if (
                (arr.includes('cshop') || arr.includes('tmall')) &&
                (arr.includes('jd') || arr.includes('douyin'))
              ) {
                self.$store.dispatch('user/changeActivedTab', 'Plat')
                localStorage.setItem('passActivedTab', 'Plat')
              }
            }
            self.$store.dispatch('user/changeUrlId', res.result.url_id)
            this.$store.dispatch('user/changeCats', this.cats)
            this.$store.dispatch('user/changeplants', this.formParam.plat)
            this.$store.dispatch('user/changeSubName', this.formParam.sub_name)
            this.$router.push({
              name: 'detail',
              query: {
                url_id: res.result.url_id ? res.result.url_id : '',
                start: parseTime(this.form.startMonth, '{y}-{m}'),
                end: parseTime(this.form.endMonth, '{y}-{m}'),
              },
            })
          }
          this.loading = false
          this.$set(this.errorItem, 'type', 'info')
          this.$set(this.errorItem, 'text', '')
        })
        .catch((e) => {
          this.searching = false
          console.log(e)
          this.loading = false
          this.$set(this.errorItem, 'type', 'error')
          this.$set(this.errorItem, 'text', e.backMsg)
        })
        .finally(() => {
          this.searching = false
          this.loading = false
        })
    },
    chooseConcept(concept) {
      this.form.title = concept
    },
    queryTitleTransAsync() {
      return titleTrans({
        plat: this.form.plat,
        lang: this.$store.state.lang.lang,
        title: this.form.title,
      })
        .then((res) => {
          this.titleTransRes = res.result.translations.map((item) => {
            return {
              value: item,
              valuecp: item,
              edit: false,
              checked: false,
            }
          })
        })
        .catch((e) => {
          this.$message({
            message: e.backMsg,
            type: 'error',
          })
        })
    },
    eidtTitleTrans(item) {
      return titleTrans(
        {
          plat: this.form.plat,
          lang: this.$store.state.lang.lang,
          title: this.form.title,
          title_trans: item.value,
        },
        'put'
      )
        .then((res) => {
          this.$message({
            message: this.$t('search.modify_success'),
            type: 'success',
          })
        })
        .catch((e) => {
          this.$message({
            message: e.backMsg,
            type: 'error',
          })
        })
    },
    // titleChangeOversea: _.debounce(function () {
    //   this._titleChangeOversea()
    // }, 100),
    titleChangeOversea() {
      if (!this.form.title) {
        this.popoverShow.title = false
        this.titleTransRes = []
        return
      }
      this.queryTitleTransAsync().then((res) => {
        this.popoverShow.title = true
      })
    },
    titleTransResClick(item) {
      this.titleTransRes.forEach((res) => {
        res.edit = false
        res.checked = false
      })
      this.$set(item, 'checked', true)
    },
    stopClick() {
      return false
    },
    titleTransEdit(item) {
      if (item.value == item.valuecp) {
        item.edit = false
        reutrn
      }
      this.eidtTitleTrans(item).then((res) => {
        this.titleTransResClick(item)
        item.valuecp = item.value
        item.edit = false
        item.checked = true
      })
    },
    hideAllPopover() {
      _.forEach(this.popoverShow, (item, key) => {
        this.popoverShow[key] = false
      })
    },
    /*
     type:查询类型  id:通过id精确查询，name：通过名称模糊查询
    */
    platChange() {},
  },
  created() {
    let filterParams = JSON.parse(sessionStorage.getItem('filterParams'))
    if (filterParams && Object.keys(filterParams).length > 0) {
      this.form.startMonth = this.$route.query.start
      this.form.endMonth = this.$route.query.end
      this.dateTime = [this.$route.query.start, this.$route.query.end]
      this.form.brand = filterParams.brand
      this.form.shop = filterParams.shop_name
      this.form.title = filterParams.title
      this.form.avg_price = filterParams.avg_price
      this.form.sold = filterParams.sold
      this.form.sales = filterParams.sales
      this.attr_show = filterParams.attr_show
      this.attr_vague = filterParams.attr_vague
      this.form.attrv = filterParams.attr_logic
      this.form.top = filterParams.allattr
      this.topAttrList = filterParams.topAttrList
      this.topAttrCustomobj = filterParams.topAttrCustomobj
      this.form.title_relation = filterParams.title_relation
      this.form.filing_name = filterParams.filing_name
      this.form.item_ids = filterParams.item_ids
      this.onlineTime = filterParams.online_time
      if (filterParams.filing_date) {
        this.filing_date = filterParams.filing_date
      }
      if (filterParams.title_trans) {
        this.form.title_trans = filterParams.title_trans
      }
      this.form.comment_url_id = filterParams.comment_url_id
      this.form.plat = filterParams.plat
      let plats = filterParams.plat.split(',')
      if (plats.length > 0) {
        let arr = plats.map((item) => {
          if (
            item == 'cshop' ||
            item == 'global' ||
            item == 'tmall' ||
            item == 'tmallhk' ||
            item == 'chaoshi' ||
            item == 'tmallhk_gfzy' ||
            item == 'nochaoshi' ||
            item == 'notmallhk' ||
            item == 'tmqjd' ||
            item == 'tmzyzmd'
          ) {
            return 'taobao'
          } else if (
            item == 'jd' ||
            item == 'jd_only_self' ||
            item == 'jd_exclude_self' ||
            item == 'jd_hk' ||
            item == 'jd_home_self' ||
            item == 'jd_home_pop' ||
            item == 'jd_not_home_self' ||
            item == 'jd_not_home_pop'
          ) {
            return 'jd'
          } else {
            return item
          }
        })
        this.platTimeName = _.uniq(arr).join()
      }
      if (this.form.plat.indexOf('tiktok') > -1) {
        this.showBrand = false
      } else {
        this.showBrand = true
      }
      this.form.cats = filterParams.cats

      // ***** processing attribute data 处理属性数据
      // if attr_filters exist
      if (
        filterParams.attr_filters.length > 0 &&
        filterParams.attr_filters[0].name != '-'
      ) {
        let cid, sub_name
        if (filterParams.cats[0] && filterParams.cats[0].custom) {
          cid = {
            custom: [filterParams.cats[0]._id],
          }
          sub_name = filterParams.cats[0].sub_name
        } else {
          const cids = filterParams.cats.map((item) => item.id)
          cid = {
            not_custom: cids,
          }
          sub_name = ''
        }
        let param = {
          brand: this.form.brand,
          brand_id: this.form.brand_id,
          cid: cid,
          plat: this.form.plat,
          shop: this.form.shop,
          shop_id: this.form.shop_id,
          sub_name: sub_name,
          time: [this.dateTime],
          title: this.form.title.trim(),
          top: this.form.top,
          title_relation: this.form.title_relation,
          site_lang: this.$i18n.locale,
        }
        this.getTopAttrLoading = true
        topAttr(param).then((res) => {
          if (res.status == 'ok') {
            this.getTopAttrLoading = false
            const result = res.result
            let topAttrListArr = result.filter((item) => item[0] !== 'filing')
            topAttrListArr.shift()
            let newDisabledArr = []
            for (let i = 0; i < topAttrListArr.length; i++) {
              newDisabledArr.push(false)
            }
            this.newDisabledArr = newDisabledArr
            let arr1 = _.filter(filterParams.attr_filters, function (o) {
              return !o.popular_attr_prompt && o.attr_type !== 'filing'
            })
            let arr2 = _.filter(filterParams.attr_filters, function (o) {
              return o.popular_attr_prompt == 'checkbox'
            })
            let filingArr = filterParams.attr_filters.filter((o) => {
              return !o.popular_attr_prompt && o.attr_type == 'filing'
            })
            if (filingArr.length > 0) {
              this.filingAttrData = result
                .filter((item) => item[0] == 'filing')
                .map((item) => {
                  return {
                    id: _.uniqueId(),
                    type: item[0],
                    name: item[1],
                    name_source: item[3] || item[2],
                  }
                })
              this.defaultFilingAttrv = filingArr
              this.$nextTick(() => {
                this.$refs.filingTable.init()
              })
            }
            this.defaultCheckList = arr2
            this.attrvList = arr1.map(function (a) {
              var obj = {
                id: _.uniqueId(),
                name: a.name,
                value: a.value,
                isDisabledArr: newDisabledArr,
              }
              return obj
            })
            if (arr1.length == 0 && filingArr.length > 0) {
              this.attrvList = [
                {
                  id: _.uniqueId(),
                  value: '',
                  type: '',
                  name: '',
                  isDisabledArr: newDisabledArr,
                },
              ]
            }
            this.topAttrData = _.map(topAttrListArr, function (item) {
              return {
                id: _.uniqueId(),
                type: item[0],
                name: item[1],
                name_source: item[3] || item[2],
              }
            })
            if (this.isOversea) {
              this.topAttrData.forEach((item) => {
                let name_show = item.name,
                  name_tip = ''
                if (this.languageMode == 'system') {
                  name_show = item.name
                  name_tip = item.name_source || ''
                } else if (this.languageMode == 'source') {
                  name_show = item.name_source || ''
                  name_tip = item.name || ''
                } else {
                  name_show = `${item.name}/${item.name_source}`
                }
                item['name_show'] = name_show
                item['name_tip'] = name_tip
              })
            } else if (this.isFenxiEn) {
              this.topAttrData.forEach((item) => {
                let name_show = item.name,
                  name_tip = ''
                if (this.fenxiLanguageMode == 'zh') {
                  name_show = item.name
                  name_tip = item.name_source || ''
                } else if (this.fenxiLanguageMode == 'en') {
                  name_show = item.name_source || ''
                  name_tip = item.name || ''
                } else {
                  console.log(item)
                  name_show = `${item.name}/${item.name_source}`
                }
                item['name_show'] = name_show
                item['name_tip'] = name_tip
              })
            }
            this.topAttrData = this.topAttrData.filter((item) => {
              if (item.name.match(/\bis_.*\b/)) {
                return false
              } else {
                return true
              }
            })
          }
        })
      }

      // ***** end processing attribute data 处理属性数据
    }
    const endYear = LuxonTime.createDateTime(this.$route.query.end).year
    if (endYear < 2022) {
      this.forbidAttr = true
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutsideTitle)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutsideTitle)
  },
  watch: {
    'form.title': {
      handler(newVal) {
        if (this.$store.state.user.isOversea) {
          if (this.form.title_trans) {
            this.form.title_trans = ''
          }
        }
      },
    },
    languageMode(val) {
      this.topAttrData.forEach((item) => {
        let name_show = item.name,
          name_tip = ''
        if (val) {
          if (val == 'system') {
            name_show = item.name
            name_tip = item.name_source || ''
          } else if (val == 'source') {
            name_show = item.name_source || ''
            name_tip = item.name || ''
          } else {
            name_show = `${item.name}/${item.name_source}`
          }
        }
        item['name_show'] = name_show
        item['name_tip'] = name_tip
      })
      this.topAttrData = [...this.topAttrData]
    },
    fenxiLanguageMode(val) {
      this.topAttrData.forEach((item) => {
        let name_show = item.name,
          name_tip = ''
        if (val) {
          if (val == 'zh') {
            name_show = item.name
            name_tip = item.name_source || ''
          } else if (val == 'en') {
            name_show = item.name_source || ''
            name_tip = item.name || ''
          } else {
            name_show = `${item.name}/${item.name_source}`
          }
        }
        item['name_show'] = name_show
        item['name_tip'] = name_tip
      })
      this.topAttrData = [...this.topAttrData]
    },
    cats(newVal, oldVal) {
      if (newVal[0] && newVal[0].custom) {
        this.cid = {
          custom: [newVal[0]._id],
        }
        this.sub_name = newVal[0].sub_name
      } else {
        const cids = newVal.map((item) => item.id)
        this.cid = {
          not_custom: cids,
        }
        this.sub_name = ''
      }
      if (
        this.sub_name == 'custom_103' ||
        this.sub_name == 'custom_104' ||
        (newVal.length > 0 &&
          newVal.every(
            (item) =>
              item.cid_path[0] == '1801' ||
              item.cid_path[0] == '50010788' ||
              item.cid_path[0] == '20085' ||
              item.cid_path[0] == '20029'
          ))
      ) {
        this.isFilingCat = true
      } else {
        this.isFilingCat = false
      }
      this.nub += 1
      if (this.nub > 2) {
        // 类目选择修改后,重置top_attr属性
        this.attrvList = []
        this.defaultFilingAttrv = []
        if (this.$refs.filingTable) {
          this.$refs.filingTable.hide()
        }
        sessionStorage.setItem('topAttrList', JSON.stringify([]))
        this.topAttrCustomobj = {}
      }
      this.getTimeList()
      if (newVal.length > 0 && !this.isOversea) {
        this.getTopDefaultAttrData()
      } else {
        this.defaultAttrvList = []
        this.checkList = []
      }
    },
    '$store.state.user.ts': {
      handler(newVal, oldVal) {
        this.ts = newVal
        this.getTimeList()
      },
      deep: true,
    },
    dateTime(val) {
      // 所选时间小于2020-01,属性置灰色
      const endTime = LuxonTime.createDateTime(val[1]).year
      if (endTime < 2022) {
        // 1. 清空已经填入的属性条件
        this.attrvList = []
        // 2. 将属性筛选置灰
        this.forbidAttr = true
      } else {
        this.forbidAttr = false
      }
    },
  },
}
</script>

<style scoped lang="less">
.views {
  margin-bottom: 16px !important;
  width: calc(100% - 24px);
}
.el-form-item {
  margin-bottom: 2px;
}
.defaultTopAttrBox {
  // max-height: 160px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.search_div {
  padding: 28px 24px 20px 24px;
  //   padding: 0 0 !important;
  //   box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
  border-radius: 0px 0px 4px 4px;
  background-color: white;
  min-height: 712px;
  margin: 0 0 0 24px;
  .filter-module {
    border: 1px solid black;
    padding: 20px 20px 10px 20px;
    max-width: 700px;
    position: relative;
    margin-top: 20px;

    .filter-module-title {
      position: absolute;
      top: -15px;
      line-height: 30px;
      background-color: white;
      padding: 0 10px;
    }
  }
  .filter-module.attribute-filter {
    width: 100%;
    max-width: 100%;
    min-width: 700px;
  }
  .dlb {
    display: inline-block;
    margin-right: 6px;
  }
  table {
    width: auto;
    min-width: 500px;
    .btColor {
      font-size: 20px;
    }
    tr {
      td {
        min-width: 100px;
      }
    }
  }
  .mr10 {
    margin-right: 10px;
  }
  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}
.keyword-search {
  height: 30px;
  font-size: 13px;
  color: #3a63f3;
  background: unset;
  border: unset;
  padding: 0;
}
.keyword-search:active,
.keyword-search:focus,
.keyword-search:hover {
  color: #3a63f3;
  background: unset;
  border: unset;
}
.selectBox {
  width: 40%;
  min-width: 560px;
  /deep/ .el-input__inner {
    border: 0;
  }
}
.title {
  //   width: 120px;
  height: 26px;
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #252525;
  line-height: 26px;
  margin-bottom: 60px;
}

.newDiscovery {
  /deep/ .el-form-item__label {
    line-height: 30px;
  }
  .timePick {
    margin-bottom: 6px;
  }
}
.timePick {
  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: start;
  }
  /deep/ .el-date-editor .el-range-separator {
    width: 7%;
  }
}
.title-trans-list {
  display: flex;
  justify-content: space-between;
  .el-icon-circle-check {
    &.checked {
      visibility: visible;
    }
    visibility: hidden;
    line-height: 28px;
    font-size: 18px;
    margin-right: 10px;
    color: #67c23a;
  }
  .title-trans-list-left {
    input {
      font-size: 14px;
    }
    p {
      font-size: 14px;
      line-height: 28px;
      cursor: pointer;
      margin: 0;
    }
    flex: 1;
  }
  .title-trans-list-right {
    margin-left: 10px;
  }
}
// .intro-btn {
//   height: 28px;
//   font-size: 14px;
//   line-height: 25px;
//   color: rgb(58, 99, 243);
//   background-color: #ffffff;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   padding: 0px 15px;
//   margin-left: 4px;
// }
</style>

<style lang="less">
.el-icon-question {
  color: @fenxiPrimaryColor;
}
</style>
